@import 'common';
@import 'home';
@import 'chat';
@import 'leaderboards';
@import 'loader';
@import 'manage';
@import 'places';
@import 'profile';
@import 'typography';
@import './components/bottom-block';
@import './components/cookie-law';
@import './components/gallery';
@import './components/images-upload';

.v-text-field__details {
    overflow: visible !important;
}

.v-alert__content {
    ul {
        padding-left: 0 !important;

        li {
            list-style: none;
        }
    }
}

.v-tour__target--highlighted {
    box-shadow: 0 0 5px rgba(81, 203, 238, 1) !important;
    border: 1px solid rgba(81, 203, 238, 1);
    z-index: inherit !important;
}

.tox .tox-toolbar-overlord, .tox .tox-toolbar__primary {
    background: #f5f5f5 !important;
}

#ot-sdk-cookie-policy {
    display: none;
}
