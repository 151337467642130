.border-b {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.w-full {
    width: 100%;
}

.h-full {
    height: 100%;
}

.scroll-smooth {
    scroll-behavior: smooth;
}
