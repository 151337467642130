.category-item:hover,
.category-item:focus {
    background-color: #eee;
}

.manage-photo-item {
    position: relative;

    .images-actions {
        position: absolute;
        top: 0;
        right: 0;
        display: flex;

        .v-btn--fab {
            width: 24px;
            height: 24px;

            .v-icon {
                font-size: 18px;
            }
        }
    }
}
