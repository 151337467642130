h1 {
    margin-bottom: .75rem;
}

h2 {
    margin-bottom: .5rem;
}

h3 {
    margin-bottom: .25rem;
}

.responsive-img {
    max-width: 100%;
    height: auto;
}

.font-mono {
    font-family: monospace, monospace;
}

.truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.cursor-pointer {
    cursor: pointer;
}
