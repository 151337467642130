.photo-container {
    position: relative;

    .btn-favourite {
        position: absolute;
        top: 10px;
        right: 10px;
    }
}

.chips {
    position: absolute;
    left: 10px;
    top: 10px;
}

.place-card {
    .photo-container {
        .place-visited, .place-not-finished {
            position: absolute;
            bottom: 0;
            color: #fff;
            padding: .25rem .85rem;
            font-size: .9rem;
        }

        .place-visited {
            right: 0;
            background-color: #05ac31;
        }

        .place-not-finished {
            left: 0;
            background-color: firebrick;
        }
    }
}

// Workaround for wrong vuetify styles
.v-tabs:not(.v-tabs--vertical).v-tabs--right>.v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes) .v-slide-group__next, .v-tabs:not(.v-tabs--vertical):not(.v-tabs--right)>.v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes) .v-slide-group__prev {
    display: none !important;
}
