.chat {
    position: fixed;
    left: 0;
    right: 0;
    transition: height 0.25s ease;
}

.messages {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}
