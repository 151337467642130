.gallery-container {
    height: 150px;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    margin: -0.25rem;

    .gallery-photo {
        cursor: pointer;
        display: inline-block;
        margin: .25rem;
    }
}

.tinybox__content__image__caption {
    max-width: 100vw;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-all;
    white-space: pre-wrap;
}
