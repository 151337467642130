.home-title {
    font-size: 1.4rem;
}

#home-bg-carousel-wrapper {
    position: fixed;
    z-index: 0;
    overflow-y: hidden;
    width: 100vw;
    left: 0;
    top: 0;
    background: #fff;

    &:before {
        position: absolute;
        display: block;
        width: 100vw;
        height: 100vh;
        z-index: 1;
        content: '';
        left: 0;
        top: 0;
        background-color: rgba(0, 0, 0, .3);
        background-image: url('/images/home-cover.png');
        background-repeat: repeat;
    }
}

.slide-fade-enter-active {
    transition: all 2s ease;
}

.slide-fade-leave-active {
    transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-fade-enter, .slide-fade-leave-to {
    transform: translateY(30px);
    opacity: 0;
}

#home-content {
    color: #fff !important;
    z-index: 1;

    h1 {
        font-size: 500%;
    }
}
