.leaderboards-page {
    .table-wrapper {
        position: relative;

        &:not(.loading) {
            table {
                padding-right: 85px;
            }
        }
    }

    .total-points-column {
        right: 0;
        position: absolute;
        top: auto;
        width: 85px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        border-left: 1px solid rgba(0, 0, 0, 0.12);
        box-shadow: -1px 0 3px 0 rgba(0, 0, 0, 0.12);
    }
}

