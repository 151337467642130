[v-cloak]:before, .loader:before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 40px;
    height: 40px;
    margin-top: -20px;
    margin-left: -20px;
    border-radius: 50%;
    border: 3px solid #ccc;
    border-top-color: #333;
    animation: spinner 0.6s linear infinite;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
}

[v-cloak] > *, .loader:before > * {
    display: none !important;
}

.loader {
    width: 100%;
    height: 100%;
}

@keyframes spinner {
    to {
        transform: rotate(360deg);
    }
}