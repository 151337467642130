.images-container {
    min-height: 150px;
    padding: 10px 10px 0 10px;
    display: flex;
    flex-wrap: wrap;
}

.images-upload-placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 130px;
    width: 100%;
}

.images-upload-image {
    margin-right: 10px;
    margin-bottom: 10px;
    width: 130px;
    height: 130px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    position: relative;

    img {
        max-height: 100%;
    }

    .images-upload-info {
        position: absolute;
        left: 0;
        top: 0;
        width: 24px;
        height: 24px;
        text-align: center;

        .v-icon {
            font-size: 18px;
        }
    }

    .images-upload-actions {
        position: absolute;
        top: 0;
        right: 0;
        display: flex;

        .v-btn--fab {
            width: 24px;
            height: 24px;

            .v-icon {
                font-size: 18px;
            }
        }
    }

    .images-upload-error {
        position: absolute;
        left: 40px;
        top: 40px;
        bottom: 40px;
        right: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(255, 255, 255, 0.25);
        border-radius: 100%;
    }

    .images-upload-progress {
        position: absolute;
        left: 0;
        bottom: 0;
    }
}
